.landing-page-header-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 10rem 2rem 10rem;
    height: 5rem;
    background-color: #fff;
    width: 100%;
    max-width: 112.5rem;
    top: 0;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.16);
    position: fixed;
    z-index: 3;
    overflow: hidden;
    margin-bottom: 6.25rem;
}

div.landing-page-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 90vw;
    margin-top: 0.4rem;
    height: 6.5rem;
}

@media screen and (max-width: 991px) {
    span.ant-badge.custom-badge {
        margin-right: 0.7rem;
    }

    button.ant-btn.ant-btn-circle.ant-btn-default.ant-btn-lg.undefined.custom-default-button {
        margin-left: 0.7rem;
    }
}

.landing-page-header-wrapper .logo {
    width: 6.5rem;
    height: 5rem;
}

.landing-page-header-wrapper .header-breadcrumb {
    width: 40rem;
    // background-color: blue;
    font-size: 1rem;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #102e44;
    li {
        &:hover {
            font-weight: 700;
            text-decoration: underline solid rgba(246, 104, 43, 1) 20%;
            text-underline-position: under;
        }
    }
}

.landing-page-header-wrapper .custom-login-button {
    position: relative;
    top: 0.625rem;
    color: #fff;
    border-radius: 3.125rem;
    width: fit-content;
    height: fit-content;
    background-color: #12805c;
}

.landing-page-header-wrapper .custom-register-button {
    position: relative;
    top: 0.625rem;
    width: 6.375rem;
    height: 2.5rem;
    border-radius: 1.25rem;
}

.landing-page-header-wrapper .action-buttons {
    float: right;
}

.profile-name-id {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
}

h4.ant-typography.margin-unset {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media screen and (max-width: 991px) {
    .profile-name-id {
        width: 45%;
    }
}

@media screen and (max-width: 991px) {
    .profile-avatar-button {
        display: none;
    }

    img.ant-image-img.logo {
        position: relative;
        left: -1.5rem;
    }

    .landing-page-header-wrapper {
        justify-content: space-around;
    }
}

@media screen and (max-width: 991px) {
    .profile-name-id {
        display: none;
    }

    .landing-page-header-bar {
        height: 6.5rem;
    }

    div.landing-page-header-wrapper {
        margin-top: 1.5rem;
    }
}

@media screen and (max-width: 991px) {
    div.ant-space-compact.ant-space-compact-block.ant-dropdown-button.custom-dropdown-button.contact-info {
        display: none;
    }
}

.register-popup-container {
    .custom-register-modal {
        float: right;
        margin-top: 6.25rem;

        .ant-modal-content {
            background-color: #f2f2f2;
        }
    }

    .register-basic-form {
        .ant-form-item {
            margin-bottom: 0.5rem;
        }
    }
}

.profile-avatar {
    position: relative;
    top: -0.3em;
    right: 0.05em;
    border: 0.01em solid black;
}

div.okrjoy-notifications {
    opacity: 1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    z-index: 5;
    border: 0.063rem solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    div.okrjoy-icon {
        font-size: 0.625rem;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.5rem;
        height: 1.5rem;
        div.okrjoy-count {
            position: relative;
            top: -150%;
            left: 70%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background-color: red;
        }
    }
    div.okrjoy-container {
        position: relative;
        left: 18.75rem;
        top: 1.25rem;
        padding: 0.5rem;
        width: 20rem;
        height: fit-content;
        background-color: #a8d1d1;
        margin-top: 1rem;
        border: 0.0.031rem solid #f7f5eb;
        border-radius: 1rem;
        box-shadow: 0.313rem 0.313rem rgba(0, 0, 0, 0.16);
        div.okrjoy-header {
            padding: 0.5rem;
            height: 2rem;
            background-color: #227c70;
            display: flex;
            border-radius: 0.5rem;
            align-items: center;
            justify-content: space-between;
            color: white;
            font-size: 1rem;
        }
        div.okrjoy-content {
            padding: 0.313rem;
            border-radius: 0.5rem;
            height: fit-content;
            margin-top: 0.313rem;
            margin-bottom: 0.313rem;
            background-color: #faecd6;
            display: flex;
            flex-direction: row;
            align-items: center;
            div.okrjoy-image {
                display: none;
            }
        }
    }
}

.LangButton {
    display: flex;
    width: 6.25rem;
    height: 3.125rem;
    // background-color: blue;
    padding-top: 1.063rem;
    border: 0rem none;
    .ant-dropdown-link {
        height: 2.5rem;
        background: linear-gradient(180deg, #f7682b 0%, #ff3e3e 100%);
        box-shadow: 0rem 0.25rem 1.25rem rgba(246, 104, 43, 0.3);
        border-radius: 1.25rem;
        color:white;
        border: 0rem none;
        &:hover{
            border: 0rem none;
            color:white;
        }
    }
}

.dropDownLang {
    width: 5.125rem;
    height: 4.688rem;
    border: 0.063rem solid #f7682b;
    border-radius: 0.625rem;
    background-color: white;
    li {
        list-style-type: none;
        margin-left: -2.5rem;
        &:hover {
            background-color: #f7682b;
            color: white;
        }
        button {
            border: 0rem none;
            background-color: inherit;
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 1240px) {
    .profile-margin {
        ol {
            li {
                padding-left: 0.95rem;
                padding-right: 0.95rem;
            }
        }
    }
}

.profile-margin {
    // margin-left: 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 6rem;
    width: 90vw;
    ol {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        font-size: 1rem;
    }

    .termsAndConditions {
        font-weight: bold;
    }

    .termsAndConditions :hover {
        border-bottom: 0.25rem solid #12805c;
    }
    .ant-btn.ant-btn-circle.ant-btn-default.ant-btn-lg.custom-default-button {
        padding: 0rem;
    }
}

.notificationBody {
    position: absolute;
    padding: 0.313rem;
    top: 6.25rem;
    z-index: 5;
    width: 20rem;
    height: 25rem;
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0.313rem rgba(0, 0, 0, 0.8);
    overflow-y: auto;
    .notificationItem {
        position: relative;
        width: 100%;
        padding: 0.313rem;
        height: fit-content;
        border-bottom: 0.063rem solid grey;
        box-shadow: 0.313rem rgba(0, 0, 0, 0.16);
        cursor: pointer;
        &:hover {
            background-color: rgb(239, 234, 234);
        }
        section {
            width: 100%;
            font-size: 0.813rem;
            padding: 0.313rem;
            height: fit-content;
            // border: 1px solid gray;
        }
    }
}

.backGroundWhite {
    background-color: white;
}
.backGroundBlue {
    background-color: rgba(205, 240, 234, 0.5);
}

// Hide the Mobile Drawer Button
@media screen and (min-width: 767px) {
    .mobile-visible {
        display: none;
    }
}

// // Responsive Styling for mobile and tab portrait
@media screen and (max-width: 400px) {
    .landing-page-header-bar {
        width: 100vw;
        height: 25vh;
        .logo {
            position: relative;
            top: 1.5vh;
            left: -3.125rem;
            width: 6rem !important;
            height: 6rem !important;
        }
        .header-breadcrumb {
            display: none;
        }
    }

    nav.ant-breadcrumb.custom-breadcrumb {
        display: none;
    }

    .mobile-header-breadcrumb {
        display: flex;
        width:6.25rem;
        flex-direction: column;
        height: fit-content;
        justify-content:space-evenly;
        // background-color: blue;
        font-size: 1rem;
        line-height: 2rem;
        padding-top: 1rem;
    }

    .mobile-header-breadcrumb:focus {
        background-color: #12805c21;
    }

    .mobile-visible {
        position: relative;
        // top: 2rem;
        left: 0.6rem;
    }

    .display-flex-row.align-center.profile-margin {
        margin-left: 0.7rem;
    }

    .drawer-register-button {
        width: 90%;
        height: 3rem;
        font-size: 1.25rem;
        border-radius: 0.3rem;
    }

    .contact-details {
        color: #319171;
        text-align: right;
    }

    .custom-login-button,
    .custom-register-button {
        display: none;
    }
    .LangButton{
        margin-left: 3.125rem;
        padding-top: 0.5rem;
    }
}
