$col-shade-green: #12805c;
$col-shade-error: #e90000;

.buyer-ui-app {
    .landing-page-header-bar {
        position: unset;

        .header-breadcrumb {
            display: none;
        }
    }

    .buyer-ui-dashboard {
        position: relative;
        top: -6.25rem;
        padding: 2em;

        .buyer-banner {
            width: 100%;
        }
    }

    .add-produce-btn {
        width: 17.5rem;
        height: 3.25rem;
        font-weight: 500;
        font-size: 1.125rem;
    }

    .app-footer-container {
        position: relative;
        top: 0rem;
    }
}

.add-produce-modal .ant-modal-content {
    padding: 2em !important;
}

.custom-masterlist-modal {
    .ant-modal-header {
        padding: 1rem 0rem;
        margin: 0 1.5rem;
        border-bottom: 0.25em solid $col-shade-green;
    }

    .ant-modal-close-x {
        color: black;
        font-size: 1.5rem;
    }

    .custom-list {
        border: 0.1em solid $col-shade-green;
        padding-bottom: 0.5em;
        min-height: 5rem;

        .ant-typography {
            width: inherit;
            background-color: $col-shade-green;
            color: white;
            border-color: $col-shade-green;
            text-align: center;
            font-weight: normal;
        }

        .produce-scroll-bar {
            overflow-y: scroll;
        }

        .custom-search {
            width: 70%;
            padding-left: 0.5em;
            padding-bottom: 0.5em;

            .ant-input-group > .ant-input:first-child {
                border-radius: 0.3em;
                font-size: smaller;
            }
        }

        .ant-list-item {
            padding: 0 1em;

            .anticon-caret-right {
                float: right;
                margin-top: 0.188rem;
                color: #c1c1c1;
            }
        }

        .produce-list-item:hover,
        .variety-list-item:hover {
            background-color: #fce3ba;
            border-left: 0.5em solid #f5a31a;
            cursor: pointer;
            .anticon-caret-right {
                color: black;
            }
        }

        .selected-produce-item,
        .selected-variety-item {
            background-color: #fce3ba;
            border-left: 0.5em solid #f5a31a;

            .anticon-caret-right {
                color: black;
            }
        }

        .selected-grade-item {
            background-color: #fce3ba;

            .ant-list-item-action {
                margin: 0;
            }
        }
    }

    .selected-grade-item {
        background-color: #fce3ba;

        .ant-list-item-action {
            margin: 0;
        }
    }

    .ant-input-group-addon {
        display: none;
    }

    .ant-table-wrapper {
        width: 100%;
    }

    th.ant-table-cell {
        text-align: center;
        background-color: $col-shade-green;
        color: white;
        padding: 0 1rem;
    }

    td.ant-table-cell {
        padding: 0 1rem;
    }
}

.match-success-modal {
    .ant-modal-confirm-title {
        text-align: center;
    }

    .ant-modal-confirm-btns {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .match-success-icon {
        font-size: 2.563rem;
        color: $col-shade-green;
    }

    .match-error-icon {
        font-size: 2.563rem;
        color: $col-shade-error;
    }

    .modal-info-text {
        text-align: center;
        font-weight: bold;
        margin-bottom: unset;
    }
}

.partial-match {
    font-size: smaller;
    background-color: #f5a31a;
    color: white !important;
    border-radius: 0.5em;
    padding: 0 0.3em;
}

.full-match {
    font-size: smaller;
    background-color: $col-shade-green;
    color: white !important;
    border-radius: 0.5em;
    padding: 0 0.3em;
}

.pay-retry {
    background-color: #f5a31a !important;
    border-color: #f5a31a !important;
    border-radius: 0.188rem;
}

.heading-alerttext {
    color: $col-shade-error !important;
}

.custom-color-change {
    color: #cacaca !important;

    .ant-statistic-content-value {
        color: #cacaca !important;
        font-size: initial;
        padding-bottom: 0.5em;
    }
}

.ant-steps-item-finish {
    .ant-steps-item-container {
        .ant-steps-item-tail::after {
            background-color: #12805c;
        }
        .ant-steps-item-subtitle {
            color: green;
        }
        .ant-steps-item-icon {
            border-color: green !important;
            .ant-steps-icon {
                color: green !important;
            }
        }
    }
}

.ant-steps-item-process {
    .ant-steps-item-container {
        .ant-steps-item-tail::after {
            background-color: orange;
        }
        .ant-steps-item-icon {
            background-color: green !important;
            border-color: green !important;
            .ant-steps-icon {
                color: white;
            }
        }
    }
}

.ant-steps-item-wait {
    .ant-steps-item-container {
        .ant-steps-item-tail::after {
            background-color: orange;
        }
        .ant-steps-item-icon {
            background-color: orange !important;
            border-color: orange !important;
            .ant-steps-icon {
                color: white;
            }
        }
    }
}

.transaction-timeline {
    margin-top: 2rem;

    .ant-timeline-item-label {
        text-align: center !important;
    }

    .ant-timeline-item-content {
        width: 300% !important;
    }

    .ant-timeline-item-head {
        font-size: large !important;
    }

    .is-complete {
        .ant-timeline-item-tail {
            border-left: 0.125rem solid #000000 !important;
        }
    }

    .is-pending {
        .ant-timeline-item-tail {
            border-left: 0.125rem dotted #000000 !important;
        }
    }
}

.table-crop-image {
    width: 5rem !important;
    height: 5rem !important;
}

.matches-table {
    .ant-table-row-level-1 {
        background-color: #fff8de;
        line-height: 1 !important;
        font-size: small;

        .more-matches-text {
            margin-left: 6vw;
        }
    }
}

.transaction-on-going-table {
    .ant-table-row-expand-icon-cell {
        padding-left: 0;
        text-align: start !important;
    }
}

.payment-custom-input {
    cursor: not-allowed;
    border: 0rem solid #fafafa;
}

div.ant-modal.payment-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    vertical-align: middle;
    align-self: center;
    justify-content: center;
    .ant-modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 29.125rem;
        resize: auto !important;
        .payment-title {
            width: 28.125rem;
            height: 2.75rem;
            position: relative;
            top: -1.5rem;
            margin-top: 0.688rem;
            background-color: #f2f2f2;
            color: #12805c;
            font-size: 1rem;
            font-weight: 500;
            left: -1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            .payment-title-text {
                margin-left: 0.75rem;
                color: green;
                width: 18.75rem;
                height: 1.188rem;
            }
        }
        .site-collapse-custom-collapse {
            display: flex;
            flex-direction: row;
            position: relative;
            top: -1.875rem;
            width: 18.75rem;
            .site-collapse-custom-panel {
                display: flex;
                flex-direction: column;
                border: none;
                width: 25rem;
                justify-content: start;
                background-color: white;
                .anticon svg {
                    position: relative;
                    top: -0.188rem;
                    left: 6.25rem;
                }
                &::after {
                    position: relative;
                    top: -4.063rem;
                    width: 26.625rem;
                    content: URL('../static/assets/Line 40.png');
                }
                .trade-summary-header {
                    position: relative;
                    top: -1.563rem;
                    left: -0.938rem;
                    width: 7.063rem;
                    margin-left: -0.313rem;
                    display: flex;
                    justify-self: start;
                    align-items: center;
                    background-color: white;
                    .trade-summary-title {
                        width: 7.063rem;
                        color: black;
                        font-weight: 500;
                        font-size: 1rem;
                    }
                }
            }
            .trade-summary-row {
                width: 31.25rem;
                position: relative;
                top: -3.125rem;
                left: -0.938rem;
                line-height: 1.188rem;
                // background-color:orange;
                div.ant-col.ant-col-6,
                div.ant-col.ant-col-18 {
                    font-weight: 400;
                    font-size: 1rem;
                    font-style: normal;
                    color: #4e4e4e;
                    span.ant-typography.inner-text {
                        line-height: 1.25rem;
                    }
                    .payment {
                        padding: 0rem;
                        line-height: 1.625rem;
                        margin-bottom: -0.625rem;
                        .payment-custom-input {
                            height: 1.125rem;
                            padding: 0rem;
                            margin: 0rem;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
        .payment-summary {
            position: relative;
            top: -5.563rem;
            width: 28.125rem;
            height: 1.188rem;
            font-style: normal;
            .paymentsummarytext {
                position: relative;
                top: -1.563rem;
                color: green;
                font-weight: 500;
                font-size: 1rem;
            }
        }
        .payment-summary-row {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            top: -5.688rem;
            // background-color: green;
            .ant-col-24.pay-amount {
                position: relative;
                top: 1.25rem;
                height: 60px;
                .payment-amount {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                }
                .amountText {
                    width: 15rem;
                    left: 5rem;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1rem;
                }
                .amount {
                    position: relative;
                    left: 5rem;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.188rem;
                }
            }
            .ant-col-24.mode-column {
                // background-color:orange
                .mode-of-payment {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                }
                div.ant-select-selector {
                    position: relative;
                    left: 1.563rem;
                    width: 16.25rem;
                    height: 2.25rem;
                    border-radius: 0.188rem;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                }
                img {
                    position: relative;
                    z-index: 4;
                    left: 16.25rem;
                }
            }
        }
        .payment-btn-block {
            position: absolute;
            top: 6.25rem;
            left: 8.563rem;
            .pay-button-btn {
                background: #12805c;
                border-radius: 0.188rem;
                border: none;
                width: 10.313rem;
                height: 2.5rem;
                color: #ffffff;
            }
        }
        .payment-btn-block-position {
            position: relative;
            top: 6.25rem;
            left: 9.063rem;
            .pay-btn-width {
                width: 6.438rem;
                height: 2.382rem;
                background: #12805c;
                color: #ffffff;
                border-radius: 0.188rem;
                border: none;
            }
        }
    }
    div.ant-modal-footer {
        display: flex;
        justify-content: center;
        button.ant-btn {
            border: none;
            color: grey;
        }
        .ant-btn-primary {
            color: blue;
        }
    }
}
.checkDraftSection {
    position: relative;
    top: 1.25rem;
    .ant-form-item-label > label {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
    }
    .ant-input {
        width: 16.25rem;
        height: 2.25rem;
        border: 0.063rem solid #888888;
        box-sizing: border-box;
        border-radius: 0.188rem;
    }
}
.other-btn-section {
    position: relative;
    top: 1.875rem;
    left: 6.25rem;
    .other-btn-save {
        width: 6.438rem;
        height: 2.5rem;
        background: #12805c;
        border-radius: 0.188rem;
        font-weight: 400;
        font-size: 0.875rem;
        color: white;
    }
    .other-btn-cancel {
        border: none;
    }
}
.doc-upload-required {
    .other-upload {
        width: 100px;
        background: #7a4545;
        border: 0.063rem solid #4285f4;
        box-sizing: border-box;
        border-radius: 0.188rem;
        color: #4285f4;
        font-weight: 400;
        font-size: 0.813rem;
    }
    label.ant-form-item-required {
        &::after {
            position: relative;
            top: 0.313rem;
            content: URL('../static/assets/Vector.png');
        }
    }
}
.pay-button-position {
    position: relative;
    left: -1.25rem;
}
.moreMatches {
    position: relative;
    top: -45px;
    left: 30px;
    cursor: pointer;
    width: 130px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}
.matchListHeaders {
    width: 100%;
    height: 50px;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-color: #12805c;
    margin-bottom: 20px;
}
.bestMatchesFullTable {
    width: 100%;
    cursor: pointer;
    box-shadow: 5px 10px #f6f6f6;
    height: 100px;
    border-radius: 25px;
    &:hover {
        background: #f7f7f7;
    }
    thead {
        background-color: grey;
        tr {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
    }
    tbody {
        tr {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
    }
    .sellrId {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
    .sellrId {
        width: 100px;
    }
    .producePhotoCat {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 200px;
        div{
            display: flex;
            flex-direction: row;
            .gradeCategory{
                margin-left: 1em !important;
                margin-right: 1em !important;
                display: flex;
                flex-direction: column;
            }
        }
    }
    .sellerDisplayFlex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: space-between;
    }
}
.background {
    background: #e0ece4;
}
.SpinnerMatch {
    display: flex;
    justify-content: center;
}
.bestMatchesRow {
    width: 100%;
    margin-bottom: 20px;
}
.ant-modal-wrap{
    top:-15vh;
}