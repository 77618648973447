div.ant-row.app-footer-container {
    width: 100%;
    background: #102E44;
    height: auto;
    // top:25rem;
    // z-index:1;
    // position: relative;
    color: #FFFFFF;
    padding: 0em 0 3em 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .companyLgContainer {
        align-self: center;
    }
    .addressContainer {
        // background-color: rgb(196, 153, 75);
        padding-top: 1%;
        .addressTitle {
            color: white;
            font-size: 200%
        }
    }
    .linkNameContainer {
        // background-color: yellow;
        // margin-top: -1rem;
        // height:130px;
        padding-top: 1%;
        // font-size: medium;
        .linkTitle {
            color: white;
            font-size: 200%
        }
        ul {
            padding: 0rem;
            li {
                list-style-type: none;
                a {
                    color: white;
                }
            }
        }
    }
    .socialMediaContainer {
        // background-color: blue;
        padding-top: 1%;
        .socialTitle {
            color: white;
            font-size: 200%
        }
        ul {
            padding: 0rem;
            li {
                list-style-type: none;
                a{
                    margin-left:0.5rem;
                }
            }
        }
    }
    .phone {
        padding-right: 5rem;
    }
    .applied-for {
        margin-top: 1.875rem;
    }
    .powered-by-text {
        padding: 0 1em;
        margin: unset;
    }
}
//responsive
@media screen and (max-width: 767px) {
    .app-footer-container {
        // position: relative;
        width: 100vw;
        background: #102E44;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        height: 16.875rem;
        // top:-30.625rem ;
        text-align: center;
        .applied-for {
            height: 12.5rem;
        }
        .footer-logo {
            width: 100%;
            background: #102E44;
            .image {
                width: 55vw;
                height: 20vw;
            }
        }
    }
}