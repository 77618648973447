$col-shade-green: #12805c;

.col-green {
    color: $col-shade-green !important;
}

.col-orange {
    color: #f5a31a !important;
}

.box-shadow {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
}

$logo-image: '../static/assets/Group 247.png';

#home {
    width: 100vw;
    display: flex;
    flex-direction: column;
    scroll-margin: 20vh;
    // background-color: #f6682b;
    background-image: url('../static/assets/Path\ 4.png');
    // background-image:url('../static/assets/Path\ 5.png');
    background-position: center center, top left;
    // background-size: contain, auto;
    background-attachment: fixed, scroll;
    background-repeat: space;

    div.ant-row.home-wrapper-row {
        width: 100vw;

        div.ant-col.pictureWrapper,
        img {
            width: 100%;
            height: 58.438rem;
        }

        div.ant-col.secondColumn {
            width: 52.5rem;
            height: 58.438rem;
            display: flex;
            // background: #ffffff;
            flex-direction: column;
            justify-content: center;

            div {
                // background-color: #12805c;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 2.563rem;
                height: 40.625rem;

                .mainTextWrapper {
                    margin: 0rem;
                    width: 34.5rem;
                    height: 26.625rem;
                    // background-color: blue;
                    display: inline-block;
                    flex-direction: column;

                    h1.ant-typography.homeTitle {
                        //font-family: 'Montserrat';
                        margin: 0rem;
                        height: fit-content;
                        font-style: normal;
                        font-weight: 900;
                        font-size: 6.75rem;

                        span {
                            color: orange;
                        }
                    }

                    h1.ant-typography.homeTitle1 {
                        margin: 0rem;
                        // background-color: yellow;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 6.75rem;
                        line-height: 8.25rem;
                    }

                    p {
                        position: relative;
                        top: 3.125rem;
                        // background-color: #4285f4;
                        width: 32rem;
                        height: 10.125rem;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 2.25rem;
                        line-height: 150%;
                    }
                }

                div.registerComponent {
                    margin: 0rem;
                    width: 32.063rem;
                    height: 5rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    background: #e7e7e7;
                    border-radius: 2.563rem;

                    button.ant-btn.ant-btn-default {
                        gap: 0.625rem;
                        padding: 1.25rem 2.5rem;
                        width: 16rem;
                        height: 4rem;
                        background: linear-gradient(180deg, #f6682b 0%, #ff3e3e 100%);
                        border-radius: 2rem;
                        font-weight: 600;
                        font-size: 1.25rem;
                        line-height: 1.5rem;
                        color: #ffffff;
                    }

                    p {
                        width: 10.938rem;
                        height: 3rem;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 150%;
                        color: #7b7b7b;
                    }
                }

                a.ant-btn.ant-btn-link.ant-btn-sm.googlePlay {
                    width: 11.125rem;
                    height: 2.625rem;
                    padding: 0;

                    img.androidImage {
                        width: 11.125rem;
                        height: 2.625rem;
                    }
                }
            }
        }
    }

    div.ant-row.secondRowInHome {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        height: 45.25rem;
        background: #fad391;

        .floatingCard {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            // background-color: blue;
            top: -6.25rem;
            width: 80vw;
            height: 43.75rem;
            border: 0.625rem solid #ffffff;
            border-radius: 3.125rem;

            div.ant-col.cardContent {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                width: 75vw;
                height: 37rem;
                background: #faf7f2;
                border: 0.063rem solid #fad391;
                box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.19);
                border-radius: 1.875rem;

                h1.ant-typography.vbTitle {
                    width: 27.5rem;
                    height: 5rem;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 4rem;
                    line-height: 5rem;
                    text-align: center;
                }

                span.ant-typography.vbText {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.25rem;
                    line-height: 150%;
                    width: 60vw;
                    height: 5.625rem;
                    text-align: center;
                }

                .cardIcons {
                    // background-color: #f5a31a;
                    // width: 75vw;
                    height: fit-content;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;

                    div.ant-col.first,
                    div.ant-col.second,
                    div.ant-col.third {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;

                        // background-color: #12805c;
                        img {
                            width: 7.5rem;
                            height: 7.5rem;
                        }

                        span.ant-typography.mainText {
                            width: 12.125rem;
                            height: 5rem;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 2rem;
                            line-height: 5rem;
                            text-align: center;
                        }

                        span.ant-typography.subText {
                            width: 16.563rem;
                            height: 5rem;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1.25rem;
                            line-height: 5rem;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

#aim {
    // margin-top: 4rem;
    width: 100%;
    height: 50rem;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-image: url('../static/assets/agronomy.png');
    background-repeat: space;
    scroll-margin: 2.5rem;

    h1.ant-typography.whyVbTitle {
        width: 40.188rem;
        height: 4.875rem;
        // line-height: 4.875rem;
        text-align: center;
        margin: 0rem;
        font-family: 'Montserrat';

        #text {
            font-style: normal;
            font-weight: 300;
            font-size: 4rem;
        }

        span {
            font-weight: 300;
            font-style: normal;
        }
    }

    span.ant-typography.whyVbText {
        width: 39.813rem;
        height: 2.25rem;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 1.5rem;
        // line-height: 150%;
    }

    .ant-row.whyVbContainer {
        width: 54.375rem;
        height: 30.5rem;
        border: 0.625rem solid rgba(247, 104, 43, 0.3);
        filter: drop-shadow(0rem 1.563rem 3.125rem rgba(0, 0, 0, 0.1));
        border-radius: 3.125rem;
        padding: 2.063rem;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        // background-color: #038b39;
        div.ant-col.firstContent {
            width: 22.25rem;
            height: 25.375rem;
            background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255) 100%);
            border: 0.063rem solid #efefef;
            border-radius: 1.875rem 0 0 1.875rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            button.ant-btn.ant-btn-default.eButton {
                width: 24.5rem;
                height: 6.25rem;
                border: 0.063rem solid #efefef;

                span.ant-typography {
                    width: 15.438rem;
                    height: 2.25rem;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.5rem;
                    line-height: 150%;
                }

                span.ant-typography.Existing {
                    color: #ff3e3e;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            button.ant-btn.ant-btn-default.eButton.Existing,
            button.ant-btn.ant-btn-default.sellerBtn.Sellers,
            button.ant-btn.ant-btn-default.buyerBtn.Buyers {
                background: rgba(247, 104, 43, 0.06);

                &:hover {
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        width: 0.5rem;
                        height: 100%;
                        background: linear-gradient(180deg, #f7682b 0%, #ff3e3e 100%);
                        transform: translateX(-50%);
                    }
                }
            }

            button.ant-btn.ant-btn-default.sellerBtn,
            button.ant-btn.ant-btn-default.buyerBtn {
                width: 24.5rem;
                height: 6.25rem;
                background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
                border: 0.063rem solid #efefef;
            }

            span.ant-typography.sellerBtnTxt,
            span.ant-typography.buyerBtnTxt {
                width: 14.625rem;
                height: 3.563rem;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 1.5rem;
                display: flex;
                flex-direction: column;
                line-height: 150%;
                align-items: flex-start;

                p {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 0.938rem;
                    line-height: 150%;
                }
            }

            span.ant-typography.Sellers,
            span.ant-typography.Buyers {
                color: #ff3e3e;
            }
        }

        div.ant-col.secondContent {
            width: 28.125rem;
            height: 25.375rem;
            background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255) 100%);
            border: 0.063rem solid #efefef;
            border-radius: 0rem 1.875rem 1.875rem 0rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            padding-top: 1.875rem;

            span.ant-typography {
                width: 22.188rem;
                height: 2.25rem;
                margin: 0rem;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 1.5rem;
                line-height: 150%;
            }

            div.ant-row {
                width: 22.188rem;
                height: 18.75rem;
                // background-color:blue;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                p.pointsTxt {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 150%;
                }
            }
        }
    }
}

#ecosystem {
    width: 100%;
    // background-color: blue;
    margin-top: 6.25rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-margin: 100px;

    div.ant-row.firstRowSolution {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100vw;
        height: fit-content;

        div.ant-col.solnFirstCol {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            // background: #ad5757;
            padding-bottom: 14.644rem;
            padding-top: 7.313rem;

            .topIcon {
                width: 4.625rem;
                height: 4.625rem;
                background: linear-gradient(180deg, #42d4f4 0%, #4285f4 100%);
                border-radius: 0.938rem;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 3.125rem;
                    height: 3.125rem;
                }
            }

            h1.ant-typography.solnTitle {
                width: 25.875rem;
                height: 4.875rem;
                font-family: 'Montserrat';
                font-size: 4em;
                font-style: normal;

                span {
                    font-weight: 310;

                    span {
                        text-decoration: underline;
                        text-decoration-color: #4285f4;
                    }
                }

                #text {
                    font-weight: 700;
                }
            }

            p.solnText {
                width: 28.313rem;
                height: 9.375rem;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 1.25em;
                line-height: 150%;

                span {
                    font-weight: 700;
                }
            }

            div.ant-row.bottomIcons {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 3.35rem;

                // background-color: blue;
                img.lineOrange {
                    width: 6.349rem;
                    height: 0.125rem;
                }
            }
        }
        div.ant-col.solnSecondCol.ant-col-xs-24.ant-col-sm-24.ant-col-md-12.ant-col-lg-32 {
            img {
                width: 42.25rem;
                height: 52.375rem;
                margin-top: 4rem;
                margin-left: 10rem;
            }
        }
    }

    div.ant-row.secondRowSolution {
        margin-top: 8.75rem;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;

        div.ant-row.keyFirstRow {
            display: flex;
            width: 100%;
            height: auto;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #102e44;
            background-image: url('../static/assets/technoBackground.png');
            background-repeat: space;

            h1.ant-typography {
                margin-top: 6rem;
                width: 58.375rem;
                height: 4.875rem;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 4em;
                line-height: 4.875rem;
                color: #ffffff;
                text-align: center;
            }

            p {
                width: 63.375rem;
                height: 3rem;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 2rem;
                line-height: 150%;
                text-align: center;
                color: #ffffff;
            }

            div.ant-row.ant-row-space-between.keyCardRow {
                position: relative;
                top: 6.25rem;
                width: 100vw;
                display: flex;
                height: auto;
                padding: 0rem 1.875rem 0rem 1.875rem;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                // background-color: #0dc741;
                z-index: 1;

                // overflow: visible;
                div.ant-col.firstBox,
                div.ant-col.secondBox,
                div.ant-col.thirdBox,
                div.ant-col.fourthBox {
                    position: relative;
                    // height: 23.75rem;
                    width: auto;
                    box-sizing: content-box;
                    border-radius: 1.563rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background: #faf7f2;
                    border: 0.25rem solid #ffffff;
                    box-shadow: 0rem 1.563rem 3.125rem rgba(0, 0, 0, 0.1);
                    border-radius: 1.875rem;
                    z-index: 10;

                    // background-color: yellow;
                    div.ant-row.titleBox {
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        height: 10.063rem;
                        border: 0.25rem solid #ffffff;
                        border-radius: 1.875rem;
                        align-items: center;
                        z-index: 10;

                        .firstImage {
                            position: relative;
                            top: -4.688rem;
                            width: 7.5rem;
                            height: 7.5rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #ffffff;
                            box-shadow: 0rem 0.625rem 1.25rem rgba(0, 0, 0, 0.1);
                            border-radius: 50%;
                        }

                        span.ant-typography.inner-text {
                            // background-color: #42d4f4;
                            position: relative;
                            top: -50px;
                            color: #ffffff;
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1rem;
                        }
                    }

                    div.ant-row.cardPoints {
                        width: 80%;
                        padding: 1.25rem;
                        height: fit-content;

                        // background-color: #038b39;
                        // margin-bottom: 73px;
                        span.ant-typography {
                            margin-top: 0.125rem;
                            margin-bottom: 0.125rem;
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 1rem;
                            line-height: 1.375rem;
                        }
                    }
                }

                .secondBox {
                    position: relative;
                    top: 4.375rem;
                }

                .fourthBox {
                    position: relative;
                    top: 4.375rem;
                }

                .firstTxt {
                    background: linear-gradient(180deg, #0dc741 0%, #038b39 100%);
                }

                .secondTxt {
                    background: linear-gradient(180deg, #42d4f4 0%, #4285f4 100%);
                }

                .thirdTxt {
                    background: linear-gradient(180deg, #f5a31a 0%, #ff7a00 100%);
                }

                .fourthTxt {
                    background: linear-gradient(180deg, #8f00ff 0%, #601af5 100%);
                }
            }

            div.ant-row.Phone-view {
                display: none;
            }
        }

        div.ant-row.keySecondRow {
            width: 100%;
            height: auto;
            background: #ffffff;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-image: url('../static/assets/technoBackground2.png');
            background-repeat: space;

            div.ant-row.keyBoxContent {
                margin-top: 13.75rem;
                margin-bottom: 3.125rem;
                width: 50%;
                height: auto;
                padding: 1.25rem;
                background: #faf7f2;
                border: 0.313rem solid #ffffff;
                box-shadow: 0rem 1.563rem 3.125rem rgba(0, 0, 0, 0.1);
                border-radius: 1.875rem;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                div.ant-col.keyBoxImage {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 1.25rem;

                    img {
                        width: 7.5rem;
                        height: 7.5rem;
                    }
                }

                div.ant-col.keyBoxTxtCont {
                    span.ant-typography.keyBoxTxt {
                        width: 57.125rem;
                        height: 8.625rem;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1.25rem;
                        line-height: 1.438rem;
                        // background-color: #0dc741;
                    }
                }
            }
        }
    }

    div.ant-row.thirdRowSolution {
        width: 105rem;
        height: 64.938rem;
        background: #e8f3d6;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        div.ant-row.dashTitle {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 22.938rem;
            height: 8.813rem;
            font-family: 'Montserrat';
            font-style: normal;
            line-height: 3.688rem;
            text-align: center;

            span.ant-typography.vkTitle {
                font-weight: 600;
                font-size: 3rem;
            }

            span.ant-typography.vkTitle1 {
                font-weight: 300;
                font-size: 3rem;
            }
        }

        div.ant-row.dashBoardRow {
            width: 54.125rem;
            height: 43.125rem;
            border: 0.625rem solid rgba(163, 187, 152, 0.4);
            filter: drop-shadow(0rem 1.563rem 3.125rem rgba(0, 0, 0, 0.1));
            border-radius: 3.125rem;
            padding: 1.25rem;

            .dashFirstCol {
                width: 100%;
                height: 39.188rem;
                background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255) 100%);
                border: 0.063rem solid #efefef;
                border-radius: 1.875rem 0rem 0rem 1.875rem;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                .dashBtnContainer {
                    width: 100%;

                    .dashBoardBtn {
                        border: 0.063rem solid #efefef;
                        width: 100%;
                        min-width: 22.25rem;
                        height: 6.25rem;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            background: rgba(0, 178, 255, 0.16);
                            cursor: pointer;
                        }

                        span.ant-typography {
                            width: 15.438rem;
                            height: 3.563rem;
                            display: block;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 1rem;
                            line-height: 1.188rem;
                            word-wrap: break-word;
                        }
                    }

                    .onClickBlue {
                        background: rgba(0, 178, 255, 0.16);
                    }
                }
            }

            .dashSecondCol {
                width: 28.125rem;
                height: 39.188rem;
                background-color: #ffffff;
                border: 0.063rem solid #efefef;
                border-radius: 0rem 1.875rem 1.875rem 0rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .dashBoardImage {
                    width: 15.625rem;
                    height: 29.348rem;
                    border-radius: 0.625rem;
                    background-repeat: no-repeat;
                }

                .dashFirstImage {
                    background-image: url('../static/assets/Rectangle 224.png');
                }

                .dashSecondImage {
                    background-image: url('../static/assets/Rectangle 225.png');
                }

                .dashThirdImage {
                    background-image: url('../static/assets/Rectangle 226.png');
                }

                .dashFourthImage {
                    background-image: url('../static/assets/Rectangle 227.png');
                }
            }
        }
    }

    div.ant-row.fourthRowSolution {
        width: 105rem;
        height: 60rem;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        span.ant-typography.keyTitle {
            width: 12.938rem;
            height: 2.438rem;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.438rem;
            text-align: center;
        }

        div.ant-row.keyFeatureBadges {
            width: 75rem;
            height: 18.75rem;
            // background-color: orange;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            div.keyBadges {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                width: 13.75rem;
                height: 17.938rem;
                background: #f7f7f7;
                border: 0.125rem solid #ffffff;
                box-shadow: 0rem 0.938rem 1.5rem rgba(0, 0, 0, 0.1);
                border-radius: 1.875rem;

                img {
                    width: 5.25rem;
                    height: 5.25rem;
                }

                span.ant-typography.keyCardTitle {
                    width: 12.664rem;
                    height: 1.313rem;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.125rem;
                    line-height: 1.313rem;
                    text-align: center;
                }

                span.ant-typography.keyCardTxt {
                    width: 12.664rem;
                    height: 3rem;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    text-align: center;
                }
            }
        }

        span.ant-typography.keyAddition {
            width: 10.875rem;
            height: 2.438rem;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.438rem;
            text-align: center;
        }

        div.ant-row.keyFeatureBottomRow {
            width: 55.063rem;
            height: 11.5rem;
            background: #fefbe9;
            border-radius: 0.625rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .line {
                width: 0rem;
                height: 5.313rem;
                border: 0.063rem solid #fad391;
            }

            div.ant-col.keyRows {
                width: 17.813rem;
                height: 8.125rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;

                // background-color: blue;
                div {
                    width: 15.063rem;
                    height: 2.563rem;
                    // background-color: #038b39;
                    text-align: center;

                    .keyMainTitle {
                        margin: 0rem;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 1.188rem;
                    }

                    .keyMainTitle1 {
                        margin: 0rem;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.125rem;
                        line-height: 1.188rem;
                        color: #f5a31a;
                    }
                }

                .keySubTxt {
                    width: 12.664rem;
                    height: 2rem;
                    text-align: center;
                    color: #102e44;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1rem;
                }
            }
        }

        .keyBadgesPhone {
            display: none;
        }
    }

    div.ant-row.bestPractices {
        width: 100%;
        height: 28.438rem;
        background: #102e44;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        div.ant-col.bestImage {
            width: 9.375rem;
            height: 9.375rem;
            background-image: url('../static/assets/cloud\ 1.png');
            background-repeat: no-repeat;
        }

        div.ant-col.bestSolnTxtContainer {
            width: 50rem;
            height: 17.5rem;
            margin-left: 4.938rem;
            // background-color: #601af5;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            h1.ant-typography.bestSolnTitle {
                width: 28.25rem;
                height: 2.438rem;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                line-height: 2.438rem;
                color: #ffffff;
            }

            p.bestSolnTxt {
                font-family: 'Montserrat';
                font-style: normal;
                line-height: 150%;
                font-size: 1.125rem;
                color: #dbd6d6;
                font-weight: 300;

                span {
                    font-weight: 700;
                    color: #ffffff;
                }
            }
        }
    }
}

#commodities {
    background: #fad391;
    width: 100%;

    .commodities-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 45rem;

        .commoditiesTitle {
            /* Commodities We Facilitate */
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 3rem;
            margin-top: 7rem;
            margin-bottom: 3rem;
            /* identical to box height */
            text-align: center;
            color: #102e44;
        }

        .commoditiesParagraph {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 1.25rem;
            width: 60%;
            margin-bottom: 5rem;
            line-height: 150%;
            /* or 30px */
            text-align: center;
            color: #102e44;
        }

        .CropImages_sec {
            width: 100%;
            height: fit-content;

            .CropImagesWeb {
                width: 100%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;

                .crops {
                    height: fit-content;
                    background: #f7f7f7;
                    border: 0.125rem solid #ffffff;
                    box-shadow: 0rem 0.938rem 1.5rem rgba(0, 0, 0, 0.1);
                    border-radius: 1.9rem;
                    padding: 1.1rem;
                    text-align: center;

                    p {
                        margin-top: 1.25rem;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.125rem;
                        margin-bottom: 0rem;
                    }

                    img {
                        width: 9.3rem;
                        height: 9.3rem;
                    }
                }
            }

            .carouselContainer {
                display: none;
            }
        }

        // Kannada Styling
        .commodities-title-kn {
            font-size: 2rem;
        }

        .commodities-title {
            font-size: 2.5rem;
        }

        // Kannada Styling
        .commodities-paragraph-kn {
            width: 100%;
            font-size: 1rem;
            line-height: 2rem;
        }

        .commodities-paragraph {
            width: 100%;
            font-size: 1rem;
            line-height: 2rem;
        }

        .commodities-list {
            float: left;
            width: 10rem;
            margin-top: 1.5rem;
        }

        .commodities-name {
            font-size: large;
        }

        .commodities-image {
            width: 9rem;
            height: 10rem;
        }

        .wheat-image {
            margin-top: 1rem;
            width: 90%;
        }
    }
}

#users {
    scroll-margin: 6rem;
    position: relative;
    bottom: 10rem;

    // Kannada Styling
    .user-title-kn {
        text-align: center;
        font-size: 2rem;
    }

    .user-title {
        text-align: center;
        font-size: 2.5rem;
    }

    .user-column {
        margin-left: 3rem;

        // Kannada Styling
        .user-card-kn {
            background-color: #e0efea;
            width: fit-content;
            font-size: 1rem;
            height: 26rem;
            text-align: center;
        }

        .user-card {
            background-color: #e0efea;
            width: fit-content;
            font-size: 1rem;
            height: 26rem;
            text-align: center;
        }
    }

    // Kannada Styling
    .card-subtitle-kn {
        font-size: 1rem;
    }

    // Kannada Styling
    .card-text-kn {
        font-size: 0.9rem;
    }

    .card-text {
        font-size: 1rem;
    }

    .user-image {
        width: 7.813rem;
        height: 7.813rem;
    }

    .transport-image {
        margin: 2rem;
        width: 90%;
    }
}

.about-us-video-modal {
    .ant-modal-body {
        height: 90vh;
    }
}

#aboutUs {
    margin: 2.5rem;
    width: 95%;
    scroll-margin: 6rem;
    padding: 1rem 2rem;
    position: relative;
    bottom: 10rem;
    background-color: #fbfbfb;

    // Kannada Styling
    .about-us-title-kn {
        padding-top: 1rem;
        font-size: 2rem;
    }

    .about-us-title {
        padding-top: 1rem;
        font-size: 2.5rem;
    }

    .outline-image {
        position: relative;
        float: right;
        bottom: 5rem;
        height: 10rem;
    }

    // Kannada Styling
    .outline-paragraph-kn {
        font-size: 1rem;
        width: 90%;
    }

    .outline-paragraph {
        font-size: 1.2rem;
        width: 90%;
    }

    .vision-card {
        width: fit-content;
        height: 17rem;
        background-color: #e0efea;
        text-align: center;

        // Kannada Styling
        .card-paragraph-kn {
            width: 15rem;
            font-size: 1rem;
            margin: 0 auto;
        }

        .card-paragraph {
            width: 18rem;
            font-size: large;
            margin: 0 auto;
        }
    }

    .vision-div {
        padding-top: 1rem;

        // Kannada Styling
        .vision-paragraph-kn {
            width: fit-content;
            font-size: 1rem;
        }

        .vision-paragraph {
            width: fit-content;
            font-size: large;
        }
    }
}

.team_back_button {
    padding-top: 1rem;
    .gradient-icon {
        color: #0c0b0b;
    }

    .gradient-text {
        background: #ffffff;
        background-clip: text;
        color: black;
        font-size: 1rem;
        font-weight: 600;
        font-family: 'Montserrat';
    }

    a {
        position: absolute;
        left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
    }
}

#team {
    margin-top: 2rem;
    width: 100%;
    // background-color: #102e44;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    scroll-margin: 7rem;

    .team_title {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .first_word {
            font-weight: 400;
        }

        .orange-box {
            background: linear-gradient(to right, #f5a31a, #f5761a);
            border-radius: 0.938rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4.6rem;
            height: 4.6rem;
        }

        .teamTitle {
            text-align: center;
            font-size: 3rem;
            line-height: 3.688rem;
            font-family: 'Montserrat';
            margin-top: 1rem;
            font-weight: 700;

            .first_word {
                font-weight: 400;
            }
        }
    }

    .team_sec {
        width: 90%;
        height: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
        background: white;
        // background-color: blue;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;

        .team_row {
            width: 80%;
            display: flex;
            flex-direction: row;
            height: fit-content;
            margin-bottom: 1rem;
            margin-top: 1rem;
            padding: 1.25rem;
            border-radius: 0.625rem;

            img {
                border: 0.125rem solid #ffffff;
                box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.1);
                border-radius: 1.875rem;
                width: 14rem;
                background-color: #f7f7f7;
                height: 18rem;
            }

            .teamPara {
                display: flex;
                width: 80%;
                height: 100%;
                flex-direction: column;
                overflow: hidden;
                margin-left: 2rem;
                margin-top: 0.625rem;
                line-height: 70%;

                .teamName {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 2.438rem;
                    /* identical to box height */
                    color: #102e44;
                }

                p {
                    margin: 0rem;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    font-size: 1.33rem;
                    color: #f6682b;
                }

                .para {
                    margin-top: 2rem;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.125rem;
                    line-height: 150%;
                    /* or 27px */
                    color: #888888;
                }
            }
        }

        div.ant-row.teamCarouselUnit {
            width: 80%;
            height: auto;
            padding: 3%;
            background: #fefbe9;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            div.ant-carousel {
                width: 300px;
                height: 400px;
                // background-color: #102e44;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                div {
                    width: 300px;
                    height: 400px;
                    // background-color: #038b39;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    div.teamContainer {
                        width: 300px;
                        height: 400px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background: #f7f7f7;
                        border: 2px solid #ffffff;
                        // box-shadow: 0rem 1rem 1rem rgba(0, 0, 0, 0.1);
                        border-radius: 30px;

                        img {
                            width: 200px;
                            height: 200px;
                        }

                        .teamName {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1.5rem;
                            // line-height: 2.438rem;
                            /* identical to box height */
                            color: #102e44;
                        }

                        p {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            font-size: 1.33rem;
                            color: #f6682b;
                        }
                    }
                }

                ul.slick-dots.slick-dots-bottom {
                    li {
                        button {
                            background-color: #0dc741;
                        }
                    }
                }
            }

            .teamDescription {
                width: 50%;
                margin-right: 5%;
                height: fit-content;
                margin-top: 2rem;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 150%;
                /* or 27px */
                color: #888888;
            }
        }
    }
}

// .partners {
//     padding-top: 5rem;
//     padding-bottom: 1rem;
//     width: 100vw;
//     background-color: #f6f6f4;
//     height: fit-content;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     margin-bottom: 0rem;
//     scroll-margin: 2.8rem;

//     .blue-box {
//         background: linear-gradient(180deg, #42d4f4 0%, #4285f4 100%);
//         border-radius: 0.938rem;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         width: 4.6rem;
//         height: 4.6rem;
//     }

//     .partnerTitle {
//         text-align: center;
//         color: #102e44;
//         font-size: 3rem;
//         line-height: 3.688rem;
//         font-family: 'Montserrat';
//         margin-top: 1rem;
//         font-weight: 700;

//         .first_word {
//             font-weight: 400;
//         }
//     }

//     .partners-phone-display {
//         display: none;
//     }
//     .web-visible-partners {
//         div.ant-carousel {
//             width: 70rem;
//             height: fit-content;
//             div.inside-carousel {
//                 width: 70rem !important;
//                 display: flex !important;
//                 height: 300px;
//                 flex-direction: row;
//                 justify-content: space-around;
//                 align-items: center;
//                 // background-color: yellow;
//                 img {
//                     // background-color: red;
//                     height: 12rem;
//                     width: 12rem;
//                     object-fit: contain;
//                 }
//             }
//             span.anticon.anticon-left.slick-arrow.slick-prev {
//                 font-size: 20px;
//                 color: #031118;
//                 &:hover {
//                     color: #00b2ff !important;
//                 }
//             }
//             span.anticon.anticon-right.slick-arrow.slick-next {
//                 font-size: 20px;
//                 color: #031015;
//                 &:hover {
//                     color: #00b2ff !important;
//                 }
//             }
//         }
//     }

//     .grid-container {
//         margin-top: 4rem;
//         display: grid;
//         height: fit-content;
//         width: 95%;
//         grid-template-columns: repeat(5, 20%);
//     }

//     .grid-item {
//         height: 100%;
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         img {
//             width: 80%;
//             height: 80%;
//             object-fit: contain;
//         }
//     }

//     .grid-item:hover {
//         background-color: #fff;
//         box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.16);
//     }
// }

#founder {
    margin-top: 5rem;
    // background-color: #038b39;
    scroll-margin: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .blue-box {
        background: linear-gradient(180deg, #42d4f4 0%, #4285f4 100%);
        border-radius: 0.938rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.6rem;
        height: 4.6rem;
    }

    .founderWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        // background-color:green;
        .founderTitle {
            text-align: center;
            color: #102e44;
            font-size: 3rem;
            line-height: 3.688rem;
            font-family: 'Montserrat';
            margin-top: 1rem;
            font-weight: 700;

            span {
                font-weight: 400;
            }
        }

        .Founders_sec {
            // margin-top: 18rem;
            width: 85%;
            // height: fit-content;
            margin-top: 5rem;
            background: white;
            // background-color: #f6682b;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            border-radius: 0.625rem;

            .FoundersRow {
                display: flex;
                flex-direction: row;
                justify-content: center;
                // height: fit-content;
                // background-color: #102e44;
                margin-bottom: 1rem;
                margin-top: 1rem;
                padding: 1.25rem;

                img {
                    border: 0.125rem solid #ffffff;
                    box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.1);
                    border-radius: 1.875rem;
                    width: 14rem;
                    background-color: #f7f7f7;
                    height: 18rem;
                }

                .FounderPara {
                    display: flex;
                    width: 85%;
                    // background-color: #42d4f4;
                    height: 100%;
                    flex-direction: column;
                    overflow: hidden;
                    margin-left: 2rem;
                    margin-top: 0.625rem;
                    line-height: 70%;

                    .founderName {
                        // background-color: #12805c;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 2rem;
                        line-height: 2.438rem;
                        /* identical to box height */
                        color: #102e44;
                    }

                    p {
                        margin: 0rem;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.25rem;
                        font-size: 1.33rem;
                        color: #f6682b;
                    }

                    .para {
                        margin-top: 2rem;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.125rem;
                        line-height: 150%;
                        /* or 27px */
                        color: #888888;
                    }
                }
            }
        }
    }

    button {
        background: linear-gradient(180deg, #f7682b 0%, #ff3e3e 100%);
        margin-bottom: 2rem;
        box-shadow: 0rem 0.25rem 1.25rem rgba(246, 104, 43, 0.3);
        border-radius: 1.25rem;

        a {
            color: #fff;
        }
    }
}

.legal {
    // position: absolute;
    width: 100%;
    height: fit-content;
    background: #102e44;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    div.ant-row.legal-title {
        //position: absolute;
        width: 30.625rem;
        height: 8.813rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        // background-color: #00b2ff;
        .titleName {
            font-family: 'Montserrat';
            font-style: normal;
            font-size: 3rem;
            line-height: 3.688rem;
            text-align: center;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .bold {
                font-weight: 500;
            }
        }
    }

    .legalBox {
        box-sizing: border-box;
        width: 70%;
        height: fit-content;
        border: 0.625rem solid rgba(0, 178, 255, 0.25);
        filter: drop-shadow(0rem 1.563rem 3.125rem rgba(0, 0, 0, 0.1));
        border-radius: 3.125rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: -1.5rem;
        margin-bottom: 6.25rem;

        // background-color: #00b2ff;
        .insideBox {
            width: 90%;
            height: fit-content;
            background: #ffffff;
            margin: 1.75rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0rem 1.563rem 3.125rem rgba(0, 0, 0, 0.1);

            div.ant-tabs-nav-list {
                width: 98%;
                height: 7.188rem;

                // background-color: #038b39;
                div.ant-tabs-tab.ant-tabs-tab-active {
                    // box-sizing: border-box;
                    height: 7.188rem;
                    background: rgba(0, 178, 255, 0.16);
                    border: 0.063rem solid #efefef;
                    // border-radius: 30px 0px 0px 0px;
                    div.ant-tabs-tab-btn {
                        width: 10.313rem;
                        height: 2.25rem;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 1.5rem;
                        line-height: 150%;
                        /* identical to box height, or 36px */
                        text-align: center;
                        color: #00b2ff;
                    }
                }

                div.ant-tabs-tab {
                    box-sizing: border-box;
                    width: 50%;
                    height: 7.188rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    background: #fafafa;
                    border: 0.063rem solid #efefef;

                    // border-radius: 0px 30px 0px 0px;
                    div.ant-tabs-tab-btn {
                        width: 9.25rem;
                        height: 2.25rem;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.5rem;
                        line-height: 150%;
                        /* identical to box height, or 36px */
                        text-align: center;
                        color: #343434;
                    }
                }

                div.ant-tabs-content-holder {
                    width: 45.188rem;
                    height: 25.5rem;

                    div.ant-tabs-content.ant-tabs-content-top {
                        background: #038b39;
                        div#ant-tabs-tabpane.ant-tabs-tabpane-active.tnc-tab {
                            background: #12805c;
                            ul {
                                width: min-content;
                                li {
                                    padding: 2em;
                                    //padding-bottom: 0;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 1rem;
                                    line-height: 150%;
                                    /* or 24px */
                                    text-align: start;
                                    justify-content: space-evenly;
                                    list-style-type: disc;
                                    color: #102e44;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#contactUs {
    height: fit-content;
    width: 100vw;
    background: #fad391;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 1;
    margin-bottom: 0rem;
    height: 100%;

    #contact_back_button {
        position: relative;
        left: -50%;

        .gradient-icon {
            color: #f5761a;
        }

        .gradient-text {
            background: linear-gradient(to right, #f5a31a, #f5761a);
            background-clip: text;
            color: transparent;
            font-size: 1.5rem;
            font-weight: 700;
            font-family: 'Montserrat';
        }

        a {
            position: absolute;
            left: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
        }
    }

    .contact-us-title {
        // position: relative;
        // top: 1.563rem;
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
    }

    .aboutUs {
        width: 80%;
        margin-top: 50px;
        height: fit-content;

        // background-color: #00b2ff;
        h1.ant-typography {
            font-family: 'Montserrat';
            text-align: center;
            font-style: normal;
            font-weight: 700;
            font-size: 2.25rem;
        }

        p {
            text-align: left;
            font-weight: 600;
            font-family: 'Montserrat';
            font-size: 1rem;
        }
    }

    div.ant-row.contactWrapper {
        // position: relative;
        // top: 100px;
        // background-color: #12805c;
        width: 100%;

        .contact-us-info {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;

            // left: 3.125rem;
            // background-color: blue;
            span.ant-typography.contact-details {
                height: 4.5rem;
                background: rgba(255, 255, 255, 0.25);
                border-radius: 2.25rem;
                padding: 0.625rem;
            }

            address.contact-details.contact-address {
                width: 21.875rem;
                height: 9.375rem;
                background: rgba(255, 255, 255, 0.25);
                padding: 0.625rem 0.625rem 0rem 0.625rem;
                border-radius: 2.25rem;
                margin-left: 0.625rem;
            }

            .mapouter {
                position: relative;
                top: -3.125rem;
                width: 100%;
                height: 11.125rem;
                border-radius: 0.625rem;
                border: 0.063rem solid #f5a31a;
                overflow-y: hidden;

                .gmap_canvas {
                    overflow: hidden;
                    background: none !important;
                    width: 100%;
                    height: 11.188rem;

                    .gmap_iframe {
                        height: 11.188rem !important;
                    }
                }
            }

            .anticon {
                font-size: x-large;
                color: black;
                padding: 1rem 1rem;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 50%;
                margin-right: 0.625rem;
            }

            .contact-address {
                position: relative;
                bottom: 2.75rem;
                left: 3.35rem;
            }
        }

        .write-feedback {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            // background-color: #00b2ff;
            .feedback-form {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 31.25rem;
                margin-bottom: 2rem;
                background: #faf7f2;
                border-radius: 1.875rem;
                text-align: center;
                padding: 0rem 1.875rem 0rem 1.875rem;
                width: 25rem;

                h4.ant-typography.write-feedback-title {
                    margin-top: 2rem;
                }

                #miform {
                    height: 25rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;

                    // background-color: yellow;
                    .ant-form-item {
                        margin-bottom: 0rem;
                        width: 21.25rem;
                        height: 4.063rem;

                        input#fullName.ant-input,
                        input#email.ant-input,
                        input#phone.ant-input {
                            height: 4.063rem;
                            background: #ffffff;
                            border: 0.063rem solid #efefef;
                            box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
                            border-radius: 2.5rem;
                        }

                        textarea#message.ant-input {
                            background: #ffffff;
                            border: 0.063rem solid #efefef;
                            box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
                            border-radius: 1.875rem;
                        }
                    }

                    .ant-btn {
                        color: white;
                        background-color: #f5a31a;
                        height: 4rem;
                        background: linear-gradient(180deg, #f5a31a 0%, #f5761a 100%);
                        border-radius: 2rem;
                        margin-top: 0.625rem !important;
                    }
                }
            }
        }
    }
}

#back_button_updates {
    // padding-top: 0.75%;
    margin-top: -30%;

    .icon2 {
        color: #181716;
    }

    .text2 {
        background: linear-gradient(to right, #181716, #181716);
        background-clip: text;
        color: transparent;
        font-size: 1rem;
        font-weight: 700;
        font-family: 'Montserrat';
    }

    a {
        position: absolute;
        left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
    }
}

#updates {
    width: 100vw;
    padding: 3.5rem;
    z-index: 1;
    // background-color: #12805c;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-margin: 7rem;
    font-family: 'Montserrat';

    .updateWrapper {
        width: 62.5rem;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
        box-shadow: 0.25rem 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.16);

        .updates-title {
            color: black;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            font-size: 2.25rem;
        }

        .oldUpdates {
            width: 100%;
            height: 2.875rem;
            display: flex;
            align-items: center;
            background-color: #12805c21;
            cursor: pointer;

            svg {
                margin-left: 0.938rem;
            }

            span.ant-typography {
                font-weight: 600;
                margin-left: 1.25rem;
            }
        }

        .card {
            width: 56.25rem;
            height: 25rem;
            padding: 1.25rem;
            // background-color: #12805c;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            img {
                width: 25rem;
                height: 18.75rem;
            }

            p {
                position: relative;
                left: 1.25rem;
                text-align: left;
                font-size: 1rem;
            }
        }
    }
}


// Styling for web page
@media screen and (min-width: 767px) {
    .mobile-home-content {
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    #home {
        .pictureWrapper {
            width: 12.5rem;
        }

        div.ant-col.secondColumn.ant-col-xs-24.ant-col-sm-24.ant-col-md-24.ant-col-xl-12.ant-col-xxl-12 {
            // align-items: center;
            // margin: 0px;
            div {
                margin-left: 0.938rem;

                h1.ant-typography.homeTitle,
                h1.ant-typography.homeTitle1 {
                    font-size: 4.375rem;
                }

                p {
                    width: 70%;
                    // font-size:25px;
                }

                div.registerComponent {
                    width: fit-content;
                    height: 9.375rem;
                    padding: 1.25rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }

                a.ant-btn.ant-btn-link.ant-btn-sm.googlePlay {
                    margin-left: 1rem;
                }
            }
        }

        div.ant-row.secondRowInHome {
            height: auto;

            .floatingCard {
                height: 90rem;

                div.ant-col.cardContent {
                    height: 90rem;
                    justify-content: flex-start;

                    .cardIcons {
                        position: relative;
                        top: 6.25rem;
                        // background-color: yellow;
                    }

                    h1.ant-typography.vbTitle {
                        font-size: 1.875rem;
                    }
                }
            }
        }
    }

    #aim {
        height: fit-content;

        h1.ant-typography.whyVbTitle {
            // background-color:blue;
            font-size: 1.875rem;
        }

        span.ant-typography.whyVbText {
            font-size: 1.25rem;
            width: 18.75rem;
            height: fit-content;
            margin-bottom: 1.875rem;
            text-align: center;
        }

        .ant-row.whyVbContainer {
            width: 100vw;
            height: 100%;

            // background-color: #00b2ff;
            div.ant-col.firstContent.ant-col-xs-24.ant-col-sm-24.ant-col-md-24.ant-col-lg-12 {
                height: 18.75rem;

                button.ant-btn.ant-btn-default.eButton,
                button.ant-btn.ant-btn-default.sellerBtn,
                button.ant-btn.ant-btn-default.buyerBtn {
                    width: 100%;
                }

                button.ant-btn.ant-btn-default.buyerBtn {
                    span {
                        width: 6.25rem;
                    }
                }
            }

            div.ant-col.secondContent {
                margin-top: 1.25rem;

                div.ant-row {
                    width: 100%;
                    justify-content: space-evenly;

                    // background-color: blue;
                    p.pointsTxt {
                        height: fit-content;
                        // background-color: #038b39;
                    }
                }
            }
        }
    }

    #ecosystem {
        h1.ant-typography.solnTitle {
            font-size: 2.5rem !important;
            text-align: center;
            // background-color: green;
        }

        p.solnText {
            // background-color: blue;
            font-size: 1rem !important;
            width: 80% !important;
        }

        div.ant-row.bottomIcons {
            width: 100vw;
        }

        div.ant-row.thirdRowSolution {
            height: fit-content;

            div.ant-row.dashBoardRow {
                width: 90vw;
                height: fit-content;

                div.ant-row.dashTitle {
                    span.ant-typography.vkTitle {
                        font-weight: 600;
                        font-size: 2rem;
                    }

                    span.ant-typography.vkTitle1 {
                        font-weight: 300;
                        font-size: 2rem;
                    }
                }
            }
        }

        div.ant-row.fourthRowSolution {
            margin-top: 5rem;

            div.ant-row.keyFeatureBadges {
                display: none;
            }

            div.keyBadgesPhone {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: auto;
                padding: 1.25rem 0rem 1.25rem 0rem;

                // background-color: #00b2ff;
                div.ant-carousel {
                    width: 13.75rem;
                    height: 17.938rem;
                    align-self: center;

                    // background-color: #038b39;
                    div.keyBadges {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        width: 13.75rem;
                        height: 17.938rem;
                        background: #f7f7f7;
                        border: 0.125rem solid #ffffff;
                        // box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.1);
                        border-radius: 1.875rem;

                        img {
                            width: 5.25rem;
                            height: 5.25rem;
                        }

                        span.ant-typography.keyCardTitle {
                            width: 12.664rem;
                            height: 1.313rem;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 1.125rem;
                            line-height: 1.313rem;
                            text-align: center;
                        }

                        span.ant-typography.keyCardTxt {
                            width: 12.664rem;
                            height: 3rem;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 0.875rem;
                            line-height: 1rem;
                            text-align: center;
                        }
                    }
                }
            }

            div.ant-row.keyFeatureBottomRow {
                height: fit-content;

                div.line {
                    display: none;
                }
            }
        }

        div.ant-row.bestPractices {
            width: 100%;
            height: 12.5%;

            div.ant-col.bestSolnTxtContainer {
                margin-left: 1rem;
                padding: 1rem;

                h1.ant-typography.bestSolnTitle {
                    font-size: 1.5rem;
                }
            }
        }
    }

    #commodities {
        .commodities-wrapper {
            height: auto;

            h1.ant-typography.commoditiesTitle {
                font-size: 2.5rem;
            }

            .CropImages_sec {
                display: flex;
                justify-content: center;
                align-items: center;

                .carouselContainer {
                    width: 21.875rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: fit-content;
                    margin-bottom: 1.25rem;

                    // background-color: #00b2ff;
                    div.ant-carousel {
                        width: 50%;
                        height: auto;
                        // position: relative;
                        top: 50%;
                        left: 50%;

                        // transform: translate(-90%, -30%);
                        // background-color: blue;
                        div.carousel-item {
                            p {
                                display: none;
                            }
                        }
                    }
                }

                .CropImagesWeb {
                    display: none;
                }
            }
        }
    }

    #updates {
        top: -32.75rem;

        .updateWrapper {
            width: 100vw;

            .site-collapse-custom-collapse {
                .site-collapse-custom-panel {
                    .card {
                        width: 90vw;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: fit-content;

                        img {
                            position: relative;
                            top: 0.313rem;
                            width: 12.5rem;
                            height: 12.5rem;
                        }

                        p {
                            position: relative;
                            top: 0.313rem;
                            width: 18.75rem;
                        }
                    }
                }
            }
        }
    }

    .legal {
        height: fit-content;

        .legalBox {
            margin-bottom: 0rem;
        }

        div.ant-row.legal-title {
            .titleName {
                font-size: 1.875rem;
            }
        }

        div.ant-tabs-nav-list {
            width: auto;

            div.ant-tabs-tab.ant-tabs-tab-active {
                width: 6.25rem;
            }
        }

        ul {
            padding-right: 1rem;
        }

        div#rc-tabs-0-tab-1.ant-tabs-tab-btn,
        div#rc-tabs-0-tab-2.ant-tabs-tab-btn {
            font-size: 0.813rem;
            width: auto;
        }
    }
    #partners{
        .partners-phone-display {
            display: flex !important;
            width: 100%;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: fit-content;
            padding: 1rem;
            // background-color: #038b39;
            margin-top: 2rem;
    
            // margin-bottom: 5rem
            div.ant-carousel {
                width: 23rem;
                height: fit-content;
    
                div.partner-carousel-item {
                    padding: 1rem;
                    padding-left: 1.25rem;
                    background-color: #fff;
                    width: 100%;
                    height: fit-content;
    
                    img {
                        // background-color: red;
                        height: 15rem;
                        width: 20rem;
                    }
                }
            }
        }
    }
}

//Styling for mobile or tab
@media screen and (max-width: 767px) {
    .mobile-display-none {
        display: none !important;
    }

    html,
    body {
        width: 100vw !important;
        overflow-x: hidden !important;
        margin: 0rem !important;
        padding: 0rem !important;
        position: fixed !important;
    }

    .fixed-card-join {
        position: fixed;
        top: 82.2vh;
        left: 0vw;
        z-index: 1;

        .join-us {
            width: 100vw;
            height: 12.5vh;
            padding: 0;
            margin: 0rem;
            border-radius: 0.4rem;
            text-align: left;

            .fixed-title {
                font-size: 1rem;
                position: relative;
                left: 5rem;
            }

            .join-image {
                width: 12.5vw;
                height: 12.5vw;
                position: relative;
                bottom: 3rem;
            }

            .join-us-reg {
                width: 25vw;
                height: 5vh;
                font-size: 1rem;
                position: relative;
                bottom: 7rem;
                left: 16rem;
            }
        }
    }

    #home {
        div.ant-row.secondRowInHome {
            height: auto;

            .floatingCard {
                height: 95rem;

                div.ant-col.cardContent {
                    height: 95rem;
                    justify-content: flex-start;

                    .cardIcons {
                        position: relative;
                        top: 12.5rem;
                        // background-color: yellow;
                    }

                    h1.ant-typography.vbTitle {
                        font-size: 1.875rem;
                    }
                }
            }
        }
    }
}

//media query for tab

@media (max-width: 1000px) {
    #ecosystem {
        div.ant-row.secondRowSolution {
            div.ant-row.keyFirstRow {
                width: 100%;
                box-sizing: border-box;
                border: 0.063rem solid black;
                display: flex;
                height: auto;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                // background-color: #4285f4;
                h1.ant-typography {
                    width: 100%;
                    margin-top: 1.875rem;
                    // margin-left: 1.875rem;
                    font-size: 1.5em;
                    // background-color: blue;
                    text-align: center;
                }

                p {
                    display: none;
                }

                div.ant-row.keyCardRow {
                    display: none !important;
                }

                div.ant-row.Phone-view {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    // left: 15%;
                    width: 100%;

                    // height: 37.5rem;
                    // background-color: blue;
                    .ant-col-24 {
                        display: flex;
                        justify-content: center;
                        width: 100%;

                        // height: 37.5rem;
                        // background-color: orange;
                        div {
                            width: 25rem;
                            display: block;
                            height: 34.25rem;

                            // background: yellow;
                            div.ant-col.firstBox,
                            div.ant-col.secondBox,
                            div.ant-col.thirdBox,
                            div.ant-col.fourthBox {
                                position: relative;
                                width: 17.5rem;
                                top: 6.25rem;
                                // transform: translate(15%, 0%);
                                left: 3.75rem;
                                height: 26.5rem;
                                box-sizing: border-box;
                                border-radius: 1.563rem;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-between;
                                background: #faf7f2;
                                border: 0.25rem solid #ffffff;
                                border-radius: 1.875rem;
                                z-index: 10;

                                // background-color: yellow;
                                div.ant-row.titleBox {
                                    width: 17.5rem;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-evenly;
                                    align-items: center;
                                    height: 10.063rem;
                                    border: 0.25rem solid #ffffff;
                                    border-radius: 1.875rem;
                                    align-items: center;
                                    z-index: 3;

                                    .firstImage {
                                        position: relative;
                                        top: -5rem;
                                        width: 7.5rem;
                                        height: 7.5rem;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        background: #ffffff;
                                        box-shadow: 0rem 0.625rem 1.25rem rgba(0, 0, 0, 0.1);
                                        border-radius: 50%;
                                    }

                                    span.ant-typography.inner-text {
                                        // background-color: #42d4f4;
                                        position: relative;
                                        top: -50px;
                                        color: #ffffff;
                                        font-family: 'Montserrat';
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: 1.125rem;
                                        line-height: 1.375rem;
                                    }
                                }

                                div.ant-row.cardPoints {
                                    width: 100%;
                                    padding: 1.25rem;
                                    height: fit-content;
                                    // background-color: #038b39;
                                    display: flex;
                                    z-index: 500;
                                    flex-direction: column;
                                    align-items: center;

                                    // margin-bottom: 73px;
                                    span.ant-typography {
                                        margin-top: 0.125rem;
                                        margin-bottom: 0.125rem;
                                        font-family: 'Montserrat';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 1.125rem;
                                        line-height: 1.375rem;
                                    }
                                }
                            }

                            .firstTxt {
                                background: linear-gradient(180deg, #0dc741 0%, #038b39 100%);
                            }

                            .secondTxt {
                                background: linear-gradient(180deg, #42d4f4 0%, #4285f4 100%);
                            }

                            .thirdTxt {
                                background: linear-gradient(180deg, #f5a31a 0%, #ff7a00 100%);
                            }

                            .fourthTxt {
                                background: linear-gradient(180deg, #8f00ff 0%, #601af5 100%);
                            }
                        }
                    }
                }
            }

            div.ant-row.keySecondRow {
                div.ant-row.keyBoxContent {
                    margin-top: 5.75rem;
                }
            }
        }
    }
    #partners {
        .web-visible-partners {
            display: none;
        }
    }
}

@media (max-width: 450px) {
    #home {
        div.ant-row.secondRowInHome {
            height: auto;

            .floatingCard {
                height: 101rem;

                div.ant-col.cardContent {
                    height: 101rem;
                    justify-content: flex-start;

                    .cardIcons {
                        position: relative;
                        top: 18.75rem;
                        // background-color: yellow;
                    }

                    h1.ant-typography.vbTitle {
                        font-size: 1.875rem;
                    }
                }
            }
        }
    }

    #ecosystem {
        div.ant-row.firstRowSolution {
            div.ant-col.solnFirstCol {
                height: 31.25rem;

                div.ant-row.bottomIcons {
                    width: 100%;
                    height: 3.35rem;
                    font-size: 0.75rem;

                    // background-color: blue;
                    img.wheatSign,
                    img.path15,
                    img.cement {
                        width: 13%;
                    }

                    img.cement1 {
                        width: 10%;
                    }

                    img.lineOrange {
                        width: 3.161rem;
                        height: 0.125rem;
                    }
                }
            }

            .solnSecondCol {
                display: none;
                // img.holdingPhone {
                //     width: 10% !important;
                // }
                // img.phoneScreen {
                //     height: 400px !important;
                // }
            }
        }

        div.ant-row.bestPractices {
            width: 100%;
            height: 20%;

            div.ant-col.bestSolnTxtContainer {
                margin-left: 1rem;
                padding: 1rem;

                h1.ant-typography.bestSolnTitle {
                    font-size: 1.5rem;
                }
            }
        }
    }

    #commodities {
        .commodities-wrapper {
            .commoditiesTitle {
                font-size: 2rem;
            }
        }
    }

    #founder {
        .founderWrapper {
            .founderTitle {
                font-size: 2.5rem;
            }
        }
    }

    #partners {
        .partnerTitle {
            font-size: 2.5rem;
        }
        .web-visible-partners {
            display: none;
        }
    }

    #team {
        .team_sec {
            div.ant-row.teamCarouselUnit {
                background: none !important;
                padding: 0px !important;
                margin-left: -20px !important;

                .teamDescription {
                    margin-top: 30px;
                    width: 300px;
                    margin-right: 0px !important;
                }
            }
        }
    }

    .legal {
        height: fit-content;

        .legalBox {
            margin: 1.2rem;
            width: fit-content;
        }
    }

    #contactUs {
        #contact_back_button {
            display: none;
        }

        div.ant-row.contactWrapper {
            .contact-us-info {
                address.contact-details.contact-address {
                    width: 17.5rem;
                    text-align: left;
                    font-size: 1rem;
                }
            }
        }
    }

    #team_back_button {
        display: none;
    }
}
