@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

$col-shade-green: #12805C;

html {
    scroll-behavior: smooth;
    width: 100%;
    overflow-y: hidden;
}

body {
    margin: 0;
    padding:0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif,'Noto Serif Kannada', serif,'Noto Sans Tamil', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    // background-color: blue;
}

.main-content {
    width: 100%;
    height: calc(100% - 4.375rem);
    display:flex;
    flex-direction: column;
    align-items: center;
    // background-color:green;
}

.main-content-banner {
    position: fixed;
    bottom: 10em;
    left: 5em;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0.625rem;
    width: 50%;
    padding: 2em;
}

.text-align-center {
    text-align: center;
}

/*flex-classes*/
.display-flex-row {
    display: flex;
    flex-direction: row;

    &.align-center {
        align-items: center;
    }

    &.align-flex-end {
        align-items: flex-end;
    }

    &.justify-content-space-bet {
        justify-content: space-between;
    }
    &.justify-content-center{
        justify-content: center;
    }
}

/*Margin and padding classes*/
.margin-l-r-1em {
    margin-left: 1em !important;
    margin-right: 1em !important;
}

.margin-t-1em {
    margin-top: 1em;
}

.margin-unset {
    margin: unset !important;
}

.margin-b-2em {
    margin-bottom: 2em;
}

.terms-and-conditions {
    margin: 0 0.3em;
}

/*width classes*/
.width-full {
    width: 100%
}
.wid150{
    width: 9.375rem;
}

/**/
.height-full {
    height: 100%;
}

/*border radius*/
.vikas-btn-radius {
    border:none;
    margin:1rem;
}

/* Color classes*/
.col-white {
    color: white !important;
}

.color-green-shade {
    border-color: $col-shade-green !important;
    color: $col-shade-green !important;
}

.col-backgroud-green {
    background-color: $col-shade-green !important;
    border-color: $col-shade-green !important;
}

.font-size-small {
    font-size: small;
}

.required-form-field::before {
    content: "* ";
    color: red;
    width: fit-content;
}

.required-form-field-after::after {
    content: "* ";
    color: red;
}

.margin-zero {
    margin: 0 !important;
}

.display-none {
    display: none !important;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.new-landing-page {
    // background-image: URL('../src/static/assets/landingPageImage.png');
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .new-landing-page-logo {
        z-index: 1;
        margin-top: 1vh;
        height: 12vh;
        width: 12vh;
        align-content: center;
        position: relative;
        left: 48vw;
    }

    .landing-title {
        color: #ffffff;
        width: 'fit-content';
        position: relative;
        font-weight: 300;
        font-size: 3rem;
        text-align: center;
    }

    .new-landing-page-button {
        width: fit-content;
        float: right;
        position: relative;
        bottom: 20vh;
    }

    .landing-footer {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.53) 100%, rgba(0, 0, 0, 0.62) 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100vw;
        text-align: center;
        height: 30vh;
        
        .footer-text {
            color: #ffffff;
            font-weight: 300;
            width: 50vw;
            text-align: center;
            margin: 0 auto;
            margin-top: 1vh;
        }

        .footer-button-text {
            color: #ffffff;
            font-weight: 300;
            margin: 1vh;
        }
    }
}

@media screen and (max-width: 767px) {
    body{
        width:100%;
        overflow-x: hidden;
        // background-color:blue;
    }
    .main-content{
        // background-color: yellow;
        height: 100vh;
    }
    .new-landing-page {
        .landing-title {
            font-size: 1.9rem;
            left: 4vw;
            bottom: 1.3vh;
        }

        .footer-text {
            font-size: 1.5rem;
        }
    }
}
